<template>
  <v-form>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="notifications.data"
      :server-items-length="notifications.meta.total"
      sort-desc
      :footer-props="footerProps"
      :options.sync="options"
      sort-by="id"
    >
      <template v-slot:item.dispatch_time="{ item }">
        {{ item.dispatch_time | dateTimeFormat }}
      </template>

      <template v-slot:item.action="{ item }">
        <div class="text-center">
          <v-list-item @click="$emit('show', item)">
            <v-list-item-title>
              <v-icon class="mr-1" small>mdi-eye</v-icon>
              {{ $t('ITEM.VIEW') }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import { isEmpty, cloneDeep } from 'lodash';
import { dateTimeFormat } from '@/filters';

export default {
  name: 'NotificationsList',

  filters: {
    dateTimeFormat,
  },

  props: {
    notifications: {
      type: Object,
      default: () => ({
        data: [],
        meta: {
          total: 0,
        },
      }),
    },
  },

  data: () => ({
    error: null,
    options: {},
    footerProps: {
      itemsPerPageOptions: [10, 50, 100],
    },
  }),

  computed: {
    ...mapState('notification', {
      prevOptions: 'options',
    }),
    loading() {
      return this.$store.getters['loading'];
    },

    params() {
      const options = this.options;

      return {
        page: options.page,
        per_page: options.itemsPerPage,
        sort_by: options.sortBy[options.sortBy.length - 1],
        order_by: options.sortDesc[options.sortDesc.length - 1]
          ? 'desc'
          : 'asc',
      };
    },

    headers() {
      return [
        {
          text: this.$t('ITEM.ID'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('ITEM.NAME'),
          value: 'name',
        },
        {
          text: this.$t('NOTIFICATIONS.SENDING_TIME'),
          value: 'dispatch_time',
        },
        {
          text: this.$t('NOTIFICATIONS.NUMBER_OF_RECIPIENTS'),
          value: 'users_quantity',
        },
        {
          text: this.$t('ITEM.AUTHOR'),
          value: 'author',
        },
        {
          text: this.$t('DATA_TABLE.ACTIONS'),
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ];
    },
  },

  watch: {
    options: {
      handler() {
        this.refreshData();
      },
      deep: true,
    },
  },

  created() {
    if (!isEmpty(this.prevOptions)) {
      this.options = cloneDeep(this.prevOptions);
    }
  },

  beforeDestroy() {
    this.$store.commit('notification/pageOptions', cloneDeep(this.options));
  },

  methods: {
    refreshData() {
      this.$emit('refresh', {
        params: Object.assign({}, this.params),
      });
    },
  },
};
</script>
