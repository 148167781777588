<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{
        $t('NOTIFICATIONS.NOTIFICATIONS_MANAGEMENT')
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        dark
        color="indigo"
        @click="
          $router.push({
            name: 'notifications.create',
          })
        "
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $t('ITEM.NEW') }} {{ $t('NOTIFICATIONS.ITEM') }}
      </v-btn>
    </v-toolbar>

    <v-sheet class="mt-5 pa-5" tile>
      <notifications-list
        :notifications="notifications"
        @show="showedNotification"
        @refresh="refreshData"
      ></notifications-list>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import NotificationsList from '@/modules/notifications/components/NotificationsList';

export default {
  name: 'NotificationsPage',

  components: {
    NotificationsList,
  },

  data: () => ({
    notifications: undefined,
  }),

  mounted() {
    this.getNotifications();
  },

  methods: {
    ...mapActions('notification', ['getNotifications']),

    refreshData(params) {
      this.getNotifications(params)
        .then(({ data }) => {
          this.notifications = data;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    showedNotification(notification) {
      this.$router.push({
        name: 'notifications.show',
        params: { id: notification.id },
      });
    },
  },
};
</script>
